<template lang="pug">
nav.navbar
  .container
    .navbar-brand
      router-link.navbar-item(to="/")
        strong.is-size-4.has-text-weight-semibold PosterVote
      //- button.navbar-burger.burger
        span.has-background-link
        span.has-background-link
        span.has-background-link
</template>

<script>
export default {}
</script>

<style lang="sass"></style>
