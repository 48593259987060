export const ROUTE_HOME = 'home'
export const ROUTE_ABOUT = 'about'
export const ROUTE_LIST_POSTERS = 'posters'
export const ROUTE_ADD_POSTER = 'add_poster'
export const ROUTE_SHOW_POSTER = 'show_poster'
export const ROUTE_EDIT_POSTER = 'edit_poster'
export const ROUTE_WEB_CONSOLE = 'web_console'

export const MUTATION_POSTERS = 'posters'
export const MUTATION_CURRENT_USER = 'current_user'
export const MUTATION_DELETE_POSTER = 'delete_poster'
