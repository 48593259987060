<template lang="pug">
.container
  .columns.is-centered
    .column.is-two-fifths
      .box
        slot
</template>

<script>
export default {}
</script>

<style lang="sass"></style>
